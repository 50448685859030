import { EventAction, SetCurrentEventAction } from './actions';
import { EventActionTypes } from './constants';

interface EventState {
  currentEvent: string | null;
}

const initialState: EventState = {
  currentEvent: null,
};

const Event = (state = initialState, action: EventAction): EventState => {
  console.log(initialState);
  console.log(action);
  
  switch (action.type) {
    case EventActionTypes.SET_CURRENT_EVENT:
      return {
        ...state,
        currentEvent: action.payload,
      };
    default:
      return state;
  }
};

export default Event;
