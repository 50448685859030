import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import Event from "./event/reducers";

export default combineReducers({
  Auth,
  Layout,
  Event
});
